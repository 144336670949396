import React, {
  Fragment,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from "react";
import {svg, SVGCore} from "@markham/svg-control";
import { useDropzone } from "react-dropzone";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { fetch } from "@markham/react-services";
import { getPDFSVGUrl } from "../static-config";
import { S3 } from "aws-sdk";
import { getS3Config } from "../static-config";
import UUID from "uuid";
import { Body } from "aws-sdk/clients/s3";
import {Loading} from "./loading";
import Cheerio from "cheerio";
import useWindowSize from "react-use-window-size";

interface MarkhamAWSCredentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  expiration: string;
}

export interface FileContextValue {
  width: number;
  height: number;
  background: string;
  foreground: string;
  foregroundSVGCore: SVGCore;
  initialScale: number;
  updateDimensions(width: number, height: number): void;
  updateBackground(value: string): void;
  updateForeground(value: string): void;
  updateForegroundSVGCore(value: SVGCore): void;
  triggerDownload(): void;
  triggerSave(): void;
  matchSizeStyle: { width: number, height: number }
}

interface PDFInfo {
  pages: number;
  bucket: string;
  pagesAvailable: string[];
}

export const FileContext = createContext<FileContextValue>({
  width: 0,
  height: 0,
  background: "",
  foreground: "",
  foregroundSVGCore: svg(0, 0),
  updateDimensions() {},
  updateBackground() {},
  updateForeground() {},
  updateForegroundSVGCore() {},
  triggerDownload() {},
  triggerSave() {},
  matchSizeStyle: { width: 0, height: 0 },
  initialScale: 1
});

export interface FileProviderProps {
  width?: number;
  height?: number;
  background?: string;
  foreground?: string;
  foregroundSVGCore?: SVGCore;
  children: ReactNode;
}

function getCheerioDimensions(element: Cheerio): { width: number, height: number } {
  if (!element.is("svg")) {
    throw new Error("Expected svg");
  }
  const widthAttribute = element.attr("width"),
    heightAttribute = element.attr("height");

  let widthAttributeValue,
    heightAttributeValue;
  // We can only deal with a px value or raw number for now
  const valueRegex = /^\d+(?:\.\d+)?(?:\s*px)?$/;

  if (typeof widthAttribute === "string" && valueRegex.test(widthAttribute)) {
    widthAttributeValue = +widthAttribute.replace(/\s*px$/, "");
  }
  if (typeof heightAttribute === "string" && valueRegex.test(heightAttribute)) {
    heightAttributeValue = +heightAttribute.replace(/\s*px$/, "");
  }
  if (typeof widthAttributeValue === "number" && typeof heightAttributeValue === "number") {
    return { width: widthAttributeValue, height: heightAttributeValue };
  }
  if (!element.is("[viewBox]")) {
    throw new Error("Expected width and height, or viewBox");
  }
  const viewBox = element.attr("viewBox");
  // The value of the viewBox attribute is a list of four numbers min-x, min-y, width and height, separated by whitespace and/or a comma
  if (!/^\d+(?:\.\d+)?[,\s]\d+(?:\.\d+)?[,\s]\d+(?:\.\d+)?[,\s]\d+(?:\.\d+)?$/.test(viewBox)) {
    throw new Error("Invalid viewBox value");
  }
  const split = viewBox.split(/[,\s]/g);
  const viewBoxWidth = +split[2],
    viewBoxHeight = +split[3];
  return {
    width: typeof widthAttributeValue === "number" ? widthAttributeValue : viewBoxWidth,
    height: typeof heightAttributeValue === "number" ? heightAttributeValue : viewBoxHeight
  };
}

export function FileProvider(props: FileProviderProps) {
  const [credentials, setCredentials] = useState<MarkhamAWSCredentials | undefined>(undefined);

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const [width, setWidth] = useState<number | undefined>(props.width);
  const [height, setHeight] = useState<number | undefined>(props.height);
  const [background, updateBackground] = useState<string | undefined>(props.background);
  const [foreground, updateForeground] = useState<string | undefined>(props.foreground);
  const [foregroundSVGCore, setForegroundSVGCore] = useState<SVGCore | undefined>(props.foregroundSVGCore);

  const [windowSize] = useMemo((): [number, number, "height" | "width"] => {
    if (windowWidth >= windowHeight) {
      return [windowWidth, width || 0, "width"];
    } else {
      return [windowHeight, height || 0, "height"];
    }
  }, [windowWidth, width, height, windowHeight]);

  const updateDimensions = useCallback((width: number, height: number) => {
    setWidth(width);
    setHeight(height);
  }, [setWidth, setHeight]);

  const updateForegroundSVGCore = useCallback((foregroundSVGCore: SVGCore) => {
    setForegroundSVGCore(foregroundSVGCore);
    updateForeground(foregroundSVGCore.getSVG());
  }, [setForegroundSVGCore, updateForeground]);

  const getOutput = useCallback(() => {
    if (!width || !height || !background || !foreground) {
      return undefined;
    }

    const backgroundSvgCore = svg(width, height, background);
    const $ = backgroundSvgCore.getCheerio();

    const foregroundElement = $(foreground);

    foregroundElement.attr("id", "foreground");
    foregroundElement.attr("data-editor", "true");

    const found = $("svg[id=foreground][data-editor]");

    if (found.length) {
      found.replaceWith(foregroundElement);
    } else {
      const foregroundContainer = $("<g />");
      foregroundContainer.append(foregroundElement);
      $("svg").append(foregroundContainer);
    }
    return $.xml();
  }, [foreground, background, height, width]);

  const triggerSave = useCallback(() => {
    if (!window.isMarkhamEmbedded) {
      return;
    }
    const output = getOutput();
    if (!output) {
      return;
    }
    // ios
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.markhamEmbeddedCallback) {
      window.webkit.messageHandlers.markhamEmbeddedCallback.postMessage({
        output
      });
    }
    // android
    if (window.markhamEmbeddedAndroid) {
      window.markhamEmbeddedAndroid.callback(output);
    }
  }, [getOutput]);

  const triggerDownload = useCallback(() => {
    const output = getOutput();
    if (!output) {
      return;
    }
    const blob = new Blob([output], { type: "image/svg+xml" });
    const element = document.createElement("a");
    const url = URL.createObjectURL(blob);
    element.setAttribute("href", url);
    element.setAttribute("download", `editor-${Date.now()}.svg`);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 30000);
  }, [getOutput]);

  const value = useMemo((): FileContextValue | undefined => {
    if (!background || !width || !height || !foregroundSVGCore || !foreground) {
      return undefined;
    }
    return {
      width,
      height,
      background,
      foreground,
      foregroundSVGCore,
      updateBackground,
      updateForeground,
      updateForegroundSVGCore,
      updateDimensions,
      matchSizeStyle: {
        width,
        height
      },
      initialScale: 1,
      triggerDownload,
      triggerSave
    };
  }, [height, width, triggerDownload, triggerSave, updateDimensions, background, foreground, foregroundSVGCore, updateBackground, updateForeground, updateForegroundSVGCore]);

  const loadedBackground = useRef<boolean>(false);

  useEffect(() => {
    if (!background || loadedBackground.current) {
      return;
    }

    const backgroundSvgCore = svg(0, 0, background);
    const backgroundSvg = backgroundSvgCore.getCheerio()("svg:not([data-editor])");

    const { width, height } = getCheerioDimensions(backgroundSvg);
    backgroundSvg.attr("width", width.toString());
    backgroundSvg.attr("height", height.toString());

    updateDimensions(width, height);

    const foregroundSvg = backgroundSvg.find("svg[id=foreground][data-editor]");

    if (!foregroundSvg.length) {
      const foregroundSvgCore = svg(width, height);
      updateForegroundSVGCore(foregroundSvgCore);
    } else {
      const { width: foregroundWidth, height: foregroundHeight } = getCheerioDimensions(foregroundSvg);
      foregroundSvg.attr("width", foregroundWidth.toString());
      foregroundSvg.attr("height", foregroundHeight.toString());
      const foregroundSvgString = Cheerio.load(foregroundSvg[0]).xml();
      foregroundSvg.remove();
      updateBackground(backgroundSvgCore.getSVG());
      updateForegroundSVGCore(
        svg(
          foregroundWidth,
          foregroundHeight,
          foregroundSvgString
        )
      );
    }

    loadedBackground.current = true;

  }, [windowSize, background, updateDimensions, updateForegroundSVGCore, updateBackground]);

  const [backgroundState, updateBackgroundWithFile] = useAsyncFn(async (background?: File) => {
    if (!background) {
      return;
    }


    if (background.type === "application/pdf") {
      return onPDF(background);
    } else {
      return onSVG(background);
    }

    async function getCredentials() {
      if (!credentials) {
        return doFetch();
      }

      const { expiration, ...rest } = credentials;

      if (!expiration) {
        return rest;
      }

      const expiresAt = new Date(expiration);

      if (expiresAt.getTime() > (Date.now() + (5 * 60 * 1000))) {
        return rest;
      }

      return doFetch();

      async function doFetch() {
        const response = await fetch(getPDFSVGUrl(), "/aws-credentials");
        const newCredentials: MarkhamAWSCredentials = await response.json();
        setCredentials(newCredentials);
        const { expiration: unused, ...fetched } = newCredentials;
        return fetched;
      }
    }

    async function onPDF(background: File) {
      const { region, bucket } = getS3Config();
      const credentials = await getCredentials();
      const client = new S3({
        ...credentials,
        region
      });

      const key = `editor-${UUID.v4()}`;

      await client.putObject({
        Bucket: bucket,
        Key: `uploads/${key}.pdf`,
        Body: background
      }).promise();

      const info = await getInfo();

      if (!info.pagesAvailable.length) {
        throw new Error("Could not get PDF pages");
      }

      loadedBackground.current = false;
      updateBackground(
        await getSVGFromKey(info.pagesAvailable[0])
      );

      async function getSVGFromKey(key: string): Promise<string> {
        const { Body } = await client.getObject({
          Bucket: bucket,
          Key: key
        }).promise();

        if (!Body) {
          throw new Error("Could not get SVG");
        }

        return readBodyAsString(Body);
      }

      async function getInfo(found: boolean = false): Promise<PDFInfo> {

        if (!found) {
          const head = await client.headObject({
            Bucket: bucket,
            Key: `downloads/${key}-info.json`
          })
            .promise()
            .catch(() => false);

          if (!head) {
            await new Promise(resolve => setTimeout(resolve, 2000));
            return getInfo();
          }
        }

        const { Body } = await client.getObject({
          Bucket: bucket,
          Key: `downloads/${key}-info.json`,
          ResponseContentType: "application/json",
          ResponseContentEncoding: "utf-8"
        }).promise();

        if (!Body) {
          throw new Error("Could not get PDF info");
        }

        const parsed: PDFInfo = readBody(Body);
        if (!parsed.pagesAvailable[0]) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          return getInfo(true);
        }
        return parsed;
      }

      function readBody(value: Body): PDFInfo {
        return JSON.parse(readBodyAsString(value));
      }

      function readBodyAsString(value: Body): string {
        if (typeof value === "string") {
          return value;
        }
        if (!(value instanceof Uint8Array)) {
          throw new Error("Could notread body");
        }
        return new TextDecoder("utf-8").decode(value)
      }

    }

    async function onSVG(background: File) {
      const reader = new FileReader();
      reader.onload = () => {
        let result = reader.result;

        if (typeof result !== "string") {
          return alert("Could not load SVG");
        }

        if (/<svg:svg/.test(result)) {
          result = result
            .replace(/<svg:/g, "<")
            .replace(/<\/svg:/g, "</")
            .replace("xmlns=", "xmlns:unknown=")
            .replace("xmlns:svg=", "xmlns=");
        }

        loadedBackground.current = false;
        updateBackground(result);
      };
      reader.readAsText(background, "utf-8");
    }

  }, [credentials, setCredentials, updateBackground]);

  useEffect(() => {
    if (!window.isMarkhamEmbedded) {
      return;
    }
    let svg = window.injectMarkhamSVG;
    if (typeof svg !== "string") {
      return;
    }
    if (/<svg:svg/.test(svg)) {
      svg = svg
        .replace(/<svg:/g, "<")
        .replace(/<\/svg:/g, "</")
        .replace("xmlns=", "xmlns:unknown=")
        .replace("xmlns:svg=", "xmlns=");
    }
    loadedBackground.current = false;
    updateBackground(svg);
  }, []);

  useEffect(() => {
    if (backgroundState.error) {
      alert("Could not load SVG");
    }
  }, [backgroundState.error]);

  const onFileDrop = useCallback((acceptedFiles: File[], rejectedFiles: File[]) => {
    if (!acceptedFiles[0] && rejectedFiles.length) {
      return alert("Please upload an SVG file");
    }
    updateBackgroundWithFile(acceptedFiles[0]).catch(alert);
  }, [updateBackgroundWithFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileDrop,
    accept: [
      "image/svg",
      "image/svg+xml",
      "application/pdf"
    ],
    noClick: !!background
  });

  if (!background && !window.isMarkhamEmbedded) {
    return (
      <div className="dropzone-container">
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ? (
              <Fragment>
                Drop a PDF or SVG file here
              </Fragment>
            ) : (
              <Fragment>
                Drag and drop a PDF or SVG file, or
                <br/>
                <button type="button">Open File Picker</button>
                {
                  backgroundState.loading ? <Loading /> : undefined
                }
              </Fragment>
            )
          }
        </div>
      </div>
    )
  }

  if (!value || backgroundState.loading) {
    return <Loading />;
  }

  return (
    <FileContext.Provider value={value}>
      {window.isMarkhamEmbedded ? undefined : <input {...getInputProps()} />}
      <div className="dropzone" {...(window.isMarkhamEmbedded ? {} : getRootProps())}>
        {props.children}
      </div>
      {
        backgroundState.loading ? <Loading /> : undefined
      }
    </FileContext.Provider>
  )
}

export function useFileContext(): FileContextValue {
  return useContext(FileContext);
}
