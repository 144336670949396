import React, {Fragment, useCallback} from "react";
import { useTransformContext } from "../../context";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faSearchPlus,
  faSearchMinus,
  faSearch,
  faArrows,
  faHexagon,
  faPencil,
  faFont, faKeyboard, faCheck
} from "@fortawesome/pro-light-svg-icons";
import { UndoRedo } from "./undo-redo";
import {MOVE, useSetTool, useToolColorContext, useToolContext, useToolResetContext} from "../context";
import {FREEHAND, POLYGON, POLYGON_FILLED, TEXT} from "../controllers";
import {ColorPicker, useChangeColor} from "./color-picker";
import {faFill, faHexagon as faHexagonSolid, faSave} from "@fortawesome/pro-solid-svg-icons";
import {useFileContext} from "../../file-context";

export function EditorToolsTopLeft() {
  const { zoomIn, zoomOut, resetTransform, setScale } = useTransformContext();
  const reset = useCallback(() => {
    resetTransform();
    setScale(1.0001);
  }, [setScale, resetTransform]);

  const { triggerSave } = useFileContext();

  const [tool] = useToolContext();
  const onMove = useSetTool(MOVE);
  const onFreehand = useSetTool(FREEHAND);
  const onPolygon = useSetTool(POLYGON);
  const onPolygonFilled = useSetTool(POLYGON_FILLED);
  const onText = useSetTool(TEXT);
  const [, onReset] = useToolResetContext();
  const [color] = useToolColorContext();
  const [changeColor, onChangeColor, onChangeColorComplete] = useChangeColor();

  return (
    <div className="editor-tools editor-tools-top-left">
      <div className="editor-tool-set">
        <button type="button" onClick={zoomIn} title="Zoom In">
          <FontAwesomeIcon icon={faSearchPlus} />
        </button>
        <button type="button" onClick={zoomOut} title="Zoom Out">
          <FontAwesomeIcon icon={faSearchMinus} />
        </button>
        <button type="button" onClick={reset} title="Reset">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
      <br />
      <UndoRedo />
      <br />
      <div className="editor-tool-set">
        <button role="option" type="button" title="Move" onClick={onMove} aria-selected={tool === MOVE}>
          <FontAwesomeIcon icon={faArrows} />
        </button>
        <button role="option" type="button" title="Filled polygon" onClick={onPolygonFilled} aria-selected={tool === POLYGON_FILLED}>
          <FontAwesomeIcon icon={faHexagonSolid} />
        </button>
        <button role="option" type="button" title="Polygon" onClick={onPolygon} aria-selected={tool === POLYGON}>
          <FontAwesomeIcon icon={faHexagon} />
        </button>
        <button role="option" type="button" title="Freehand" onClick={onFreehand} aria-selected={tool === FREEHAND}>
          <FontAwesomeIcon icon={faPencil} />
        </button>
        <button role="option" type="button" title="Text" onClick={onText} aria-selected={tool === TEXT}>
          <FontAwesomeIcon icon={faFont} />
        </button>
      </div>
      <br />
      {
        tool === TEXT ? (
          <div className="editor-tool-set">
            <button type="button" title="Re-enter text" onClick={onReset}>
              <FontAwesomeIcon icon={faKeyboard} />
            </button>
            <button type="button" title="Save text" onClick={onMove}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        ) : undefined
      }
      <br />
      <div className="editor-tool-set">
        <button type="button" title="Change color" onClick={onChangeColor}>
          <FontAwesomeIcon icon={faFill} color={color} />
        </button>
      </div>
      <ColorPicker enabled={changeColor} onComplete={onChangeColorComplete} />
      {
        window.isMarkhamEmbedded ? (
          <Fragment>
            <br />
            <div className="editor-tool-set">
              <button type="button" title="Save" onClick={triggerSave}>
                <FontAwesomeIcon icon={faSave} />
              </button>
            </div>
          </Fragment>
        ) : undefined
      }
    </div>
  );
}
